import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Development options</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Other frameworks?</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "development-options"
    }}>{`Development options`}</h2>
    <p>{`The Carbon Design System supports vanilla JS, React and Angular as core parts of the product. But you can still build components even if you’re using a different framework.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Using just the styles</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Wrapping a component with a JavaScript framework of your choice</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Examples</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Troubleshooting</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "using-just-the-styles"
    }}>{`Using just the styles`}</h3>
    <p>{`Developers wanting to build in different ways follow the instructions for the `}<a parentName="p" {...{
        "href": "/getting-started/developers/vanilla"
      }}>{`Vanilla`}</a>{` library to access the styles and build out their own components.`}</p>
    <h3 {...{
      "id": "wrapping-components-with-javascript-frameworks"
    }}>{`Wrapping components with JavaScript frameworks`}</h3>
    <p>{`Many JavaScript frameworks have a mechanism to dynamically create/destroy DOM elements, for example, upon change in array.
This often makes it unclear when the DOM element to instantiate a Carbon component is available, which often depends on the JavaScript framework you use.`}</p>
    <p>{`Also when DOM elements that Carbon components have been instantiated on are being destroyed, the Carbon component instances should be released so that e.g. there are no zombie event handlers.`}</p>
    <p>{`The easiest way to hook on the creation/destruction of DOM elements is by defining a “wrapping component”, with the JavaScript framework of your choice. Here’s an example using Web Components’ `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Web_Components/Custom_Elements"
      }}>{`Custom Elements v1 spec`}</a>{`, but the notion of components, along with the lifecycle callbacks, are commonly found in many other JavaScript frameworks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class BXLoading extends HTMLElement {
  // Called when this custom element gets into render tree
  connectedCallback() {
    // "this" here is "<bx-loading>" element
    this.innerHTML =
      '(e.g. snippet from http://carbondesignsystem.com/components/loading/code)';
    this.loading = CarbonComponents.Loading.create(
      this.querySelector('[data-loading]')
    );
  }
  // Called when this custom element gets out of render tree
  disconnectedCallback() {
    this.loading.release();
  }
}
customElements.define('bx-loading', BXLoading);
`}</code></pre>
    <h3 {...{
      "id": "examples"
    }}>{`Examples`}</h3>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/asudoh/pen/VryJBO?editors=1010"
      }}>{`Angular 4 wrapper for Carbon`}</a>{` (We use this just as an example. `}<a parentName="p" {...{
        "href": "https://github.com/ibm/carbon-components-angular"
      }}>{`Angular`}</a>{` is now supported by the Carbon Design System.)`}</p>
    <h3 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h3>
    <p>{`If you experience any issues while getting set up with Carbon Components, please head over to the `}<a parentName="p" {...{
        "href": "https://github.com/ibm/carbon-components"
      }}>{`Carbon Components GitHub repo`}</a>{` for more guidelines and support. Please `}<a parentName="p" {...{
        "href": "https://github.com/ibm/carbon-components/issues"
      }}>{`create an issue`}</a>{` if your issue does not already exist.`}</p>
    <h2 {...{
      "id": "other-frameworks"
    }}>{`Other frameworks?`}</h2>
    <p>{`We are open to the idea of providing core support for additional frameworks in future. We added Angular support in the summer of 2018 and Vue in early 2019.`}</p>
    <p>{`For the Carbon Design System to incorporate additional frameworks into our core offering, those frameworks need clear, guaranteed, ongoing resources to maintain and support that option. If a team built a product using a native web components implemenation of Carbon, for example, but had no plan to support that solution outside of their product, that couldn’t become a core part of the Carbon Design System.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      